// Definitions
import type { BenefitPlanType } from "models/Benefits";

export const benefitsFeatures = [
  {
    title: "features.items.0.title",
    items: [
      {
        icon: "Check",
        text: "features.items.0.opts.0.text",
      },
      {
        icon: "Check",
        text: "features.items.0.opts.1.text",
      },
      {
        icon: "Check",
        text: "features.items.0.opts.2.text",
      },
      {
        icon: "Check",
        text: "features.items.0.opts.3.text",
      },
    ],
  },
  {
    title: "features.items.1.title",
    items: [
      {
        icon: "Check",
        text: "features.items.1.opts.0.text",
      },
      {
        icon: "Check",
        text: "features.items.1.opts.1.text",
      },
      {
        icon: "Check",
        text: "features.items.1.opts.2.text",
      },
      {
        icon: "Check",
        text: "features.items.1.opts.3.text",
      },
      {
        icon: "Check",
        text: "features.items.1.opts.4.text",
      },
      {
        icon: "Check",
        text: "features.items.1.opts.5.text",
      },
      {
        icon: "Check",
        text: "features.items.1.opts.6.text",
      },
    ],
  },
];

export const benefitsMetrics = [
  {
    count: "metrics.0.count",
    desc: "metrics.0.desc",
    hasLetter: true,
  },
  {
    count: "metrics.1.count",
    desc: "metrics.1.desc",
    hasLetter: false,
  },
  {
    count: "metrics.2.count",
    desc: "metrics.2.desc",
    hasLetter: false,
  },
];

const BENEFIT_PLAN_COUNTRY_CODE = {
  FR: "FR",
  DE: "DE",
  EN: "EN",
  AT: "AT",
  NL: "NL",
} as const;

export const benefitsPlans: Record<string, BenefitPlanType[]> = {
  [BENEFIT_PLAN_COUNTRY_CODE.EN]: [
    {
      id: 1,
      desc: "benefits:plans.items.0.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 2,
      desc: "benefits:plans.items.1.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 3,
      desc: "benefits:plans.items.2.desc",
      silver: {
        value: "benefits:plans.items.2.silver.value",
      },
      gold: { value: "benefits:plans.items.2.gold.value" },
      platinum: { value: "benefits:plans.items.2.platinum.value" },
    },
    {
      id: 4,
      desc: "benefits:plans.items.3.desc",
      silver: { value: "benefits:plans.items.3.silver.value" },
      gold: { value: "benefits:plans.items.3.gold.value" },
      platinum: { value: "benefits:plans.items.3.platinum.value" },
    },
    {
      id: 5,
      desc: "benefits:plans.items.4.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 6,
      desc: "benefits:plans.items.5.desc",
      silver: { icon: "CloseCircle" },
      gold: { value: "benefits:plans.items.5.gold.value" },
      platinum: { value: "benefits:plans.items.5.platinum.value" },
    },
  ],
  [BENEFIT_PLAN_COUNTRY_CODE.DE]: [
    {
      id: 1,
      desc: "benefits:plans.items.0.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 2,
      desc: "benefits:plans.items.1.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 3,
      desc: "benefits:plans.items.2.desc",
      silver: {
        value: "benefits:plans.items.2.silver.value",
      },
      gold: { value: "benefits:plans.items.2.gold.value" },
      platinum: { value: "benefits:plans.items.2.platinum.value" },
    },
    {
      id: 4,
      desc: "benefits:plans.items.3.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 5,
      desc: "benefits:plans.items.4.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 6,
      desc: "benefits:plans.items.5.desc",
      silver: { icon: "CloseCircle" },
      gold: { value: "benefits:plans.items.5.gold.value" },
      platinum: { value: "benefits:plans.items.5.platinum.value" },
    },
  ],
  [BENEFIT_PLAN_COUNTRY_CODE.FR]: [
    {
      id: 1,
      desc: "benefits:plans.items.0.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 2,
      desc: "benefits:plans.items.1.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 3,
      desc: "benefits:plans.items.2.desc",
      silver: {
        value: "benefits:plans.items.2.silver.value",
      },
      gold: { value: "benefits:plans.items.2.gold.value" },
      platinum: { value: "benefits:plans.items.2.platinum.value" },
    },
    {
      id: 4,
      desc: "benefits:plans.items.3.desc",
      silver: { value: "benefits:plans.items.3.silver.value" },
      gold: { value: "benefits:plans.items.3.gold.value" },
      platinum: { value: "benefits:plans.items.3.platinum.value" },
    },
    {
      id: 5,
      desc: "benefits:plans.items.4.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 6,
      desc: "benefits:plans.items.5.desc",
      silver: { icon: "CloseCircle" },
      gold: { value: "benefits:plans.items.5.gold.value" },
      platinum: { value: "benefits:plans.items.5.platinum.value" },
    },
    {
      id: 7,
      desc: "benefits:plans.items.6.desc",
      silver: { icon: "CloseCircle" },
      gold: { icon: "CloseCircle" },
      platinum: { icon: "CheckCircleFilled" },
    },
  ],
  [BENEFIT_PLAN_COUNTRY_CODE.AT]: [
    {
      id: 1,
      desc: "benefits:plans.items.0.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 2,
      desc: "benefits:plans.items.1.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 3,
      desc: "benefits:plans.items.2.desc",
      silver: {
        value: "benefits:plans.items.2.silver.value",
      },
      gold: { value: "benefits:plans.items.2.gold.value" },
      platinum: { value: "benefits:plans.items.2.platinum.value" },
    },
    {
      id: 4,
      desc: "benefits:plans.items.3.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 5,
      desc: "benefits:plans.items.4.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 6,
      desc: "benefits:plans.items.5.desc",
      silver: { icon: "CloseCircle" },
      gold: { value: "benefits:plans.items.5.gold.value" },
      platinum: { value: "benefits:plans.items.5.platinum.value" },
    },
  ],
  [BENEFIT_PLAN_COUNTRY_CODE.NL]: [
    {
      id: 1,
      desc: "benefits:plans.items.0.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 2,
      desc: "benefits:plans.items.1.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 3,
      desc: "benefits:plans.items.2.desc",
      silver: {
        value: "benefits:plans.items.2.silver.value",
      },
      gold: { value: "benefits:plans.items.2.gold.value" },
      platinum: { value: "benefits:plans.items.2.platinum.value" },
    },
    {
      id: 4,
      desc: "benefits:plans.items.3.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 5,
      desc: "benefits:plans.items.4.desc",
      silver: { icon: "CheckCircleFilled" },
      gold: { icon: "CheckCircleFilled" },
      platinum: { icon: "CheckCircleFilled" },
    },
    {
      id: 6,
      desc: "benefits:plans.items.5.desc",
      silver: { icon: "CloseCircle" },
      gold: { value: "benefits:plans.items.5.gold.value" },
      platinum: { value: "benefits:plans.items.5.platinum.value" },
    },
  ],
};

export const benefitsComingFeatures = [
  {
    items: [
      {
        icon: "Check",
        text: "comingFeatures.items.0.opts.0.text",
      },
      {
        icon: "Check",
        text: "comingFeatures.items.0.opts.1.text",
      },
      {
        icon: "Check",
        text: "comingFeatures.items.0.opts.2.text",
      },
    ],
  },
  {
    items: [
      {
        icon: "Check",
        text: "comingFeatures.items.1.opts.0.text",
      },
      {
        icon: "Check",
        text: "comingFeatures.items.1.opts.1.text",
      },
      {
        icon: "Check",
        text: "comingFeatures.items.1.opts.2.text",
      },
    ],
  },
];

export const benefitsLead = {
  title: "benefits:lead.title",
  btn: "benefits:lead.btn",
};
