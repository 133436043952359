export const scrollTo = (element: string) => {
  import("react-scroll/modules/mixins/scroller")
    .then(({ default: scroller }) => {
      return scroller.scrollTo(element, {
        duration: 500,
        delay: 500,
        smooth: true,
      });
    })
    .catch((err) => {
      throw err;
    });
};
