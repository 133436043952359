// Core
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

// Components
import { Leads } from "../Leads";

// Utils
import { introLead } from "data/intro";
import { benefitsLead } from "data/benefits";
import { ScrollEnum } from "data/common";
import { book } from "init/book";
import { GET_ENUMS, GET_PARAMS } from "utils/constants";

type IntroLeadProps = {
  type: "feedback" | "signup" | "signin" | "benefits";
};

export const IntroLead = (props: IntroLeadProps) => {
  const { type } = props;

  const { t } = useTranslation();
  const router = useRouter();

  if (type === "signup") {
    return (
      <Leads
        title={t(introLead.title)}
        buttonText={t(introLead.btn)}
        scrollName={ScrollEnum.toHeader}
        type="primary"
      />
    );
  }

  if (type === "signin") {
    return (
      <Leads
        title={t(introLead.title)}
        buttonText={t(introLead.btn)}
        scrollName={ScrollEnum.toIntroInTouch}
        type="primary"
      />
    );
  }

  if (type === "benefits") {
    return (
      <Leads
        title={t(benefitsLead.title)}
        buttonText={t(benefitsLead.btn)}
        href={{ pathname: book.dashboard }}
      />
    );
  }

  if (type === "feedback") {
    return (
      <Leads
        testId="intro-feedback"
        title={t("intro:feedback.lead.title")}
        buttonText={t("intro:feedback.lead.btn")}
        href={{
          pathname: router.pathname,
          query: { [GET_PARAMS.popup]: GET_ENUMS.popup.callBack },
        }}
      />
    );
  }

  return null;
};
