// Core
import { useRouter } from "next/router";
import cx from "classnames";

// Components
import { Row, Col, Grid } from "antd";
import { Title } from "components/ui/Typography/Title";
import { Container } from "components/common/Container";
import { Button } from "components/ui/Button";

// Domain
import { useAuth } from "bus/auth/hooks/useAuth";

// Utils
import { rowGutters } from "utils/constants";
import { scrollTo } from "utils/scroll-to";
import { ScrollEnum } from "data/common";
import st from "./Styles.module.less";

type LeadsPropsType = {
  title: string;
  buttonText: string;
  href?: string | { pathname: string; query?: { [key: string]: string } };
  scrollName?: ScrollEnum;
  testId?: string;
  type?: "base" | "primary";
};

export const Leads = (props: LeadsPropsType) => {
  const { title, buttonText, href = "", scrollName = "", testId = "", type = "base" } = props;
  const router = useRouter();
  const { md, lg } = Grid.useBreakpoint();
  const { isAuthenticated } = useAuth();
  const isResponsive = !isAuthenticated;
  const isTablet = !lg;
  const isMobile = !md;

  const leadsStyles = cx(
    st.leads,
    isResponsive && {
      [st.mobile]: isMobile,
      [st.tablet]: isTablet,
    },
    {
      [st[`leads-type-${type}`]]: Boolean(type),
    },
  );
  const titleColProps = isResponsive ? { xs: { span: 24 }, md: { span: 18 } } : { span: 18 };
  const actionColProps = isResponsive ? { xs: { span: 24 }, md: { span: 6 } } : { span: 6 };
  const testIdRoot = testId ? `${testId}-leads` : "leads";
  const testIdBtn = testId ? `${testId}-leads-btn` : "leads-btn";

  const handleClick = async () => {
    await router.push(href);
  };

  const handleScroll = () => {
    scrollTo(scrollName);
  };

  return (
    <div className={leadsStyles} data-test-id={testIdRoot}>
      <Container>
        <Row gutter={rowGutters} justify="space-between" align="middle">
          <Col {...titleColProps}>
            {!isTablet && !isMobile && (
              <Title tag="h2" size="36" color="white-100" font="second" fontWeight="700">
                {title}
              </Title>
            )}
            {isTablet && !isMobile && (
              <Title tag="h2" size="24" color="white-100" font="second" fontWeight="700">
                {title}
              </Title>
            )}
            {isMobile && (
              <Title
                tag="h2"
                size="20"
                color="white-100"
                font="second"
                fontWeight="700"
                alignmentHorizontal="center"
                className={st["lead-header-mobile"]}
              >
                {title}
              </Title>
            )}
          </Col>
          <Col {...actionColProps}>
            <Button
              testId={testIdBtn}
              size="large"
              type="primary"
              block
              onClick={scrollName ? handleScroll : handleClick}
            >
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
